module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"mammasports","defaultLang":"de-de","path":"/preview"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mammasports","short_name":"mammasports","start_url":"/","background_color":"#ED691E","theme_color":"#ED691E","display":"minimal-ui","icon":"static/ms_fav.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"199756d88d8fb09d9cbd4187d0917491"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
